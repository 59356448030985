<template>
  <div class="wrapper">
    <h1>{{ $t("new.PageNotFound") }}</h1>
    <v-btn to="/">{{ $t("buttons.goMain") }}</v-btn>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    font-size: 40px;
    color: #fff;
    margin-bottom: 15px;
  }
}
</style>